import React from "react"
import Layout from "../components/layout"
import { Card } from "react-bootstrap"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import mp3 from "../../static/images/PBrownInterview.mp3"
import Paragraph from "../components/Poem"
import { Helmet } from "react-helmet"
import PAImages from "../components/PAImage"

const poem =
  "Behind the walls and wire fences" +
  "\n" +
  "the locks and razor wire" +
  "\n" +
  "a young man walks to writing class" +
  "\n" +
  "and his youthful heart on fire.$" +
  "\n" +
  "He’s learned to write good poetry" +
  "\n" +
  "and he has a girl at home" +
  "\n" +
  "and his love flows through the keyboard" +
  "\n" +
  "as he writes his girl a poem.$" +
  "\n" +
  "He wrote it in his cell last night" +
  "\n" +
  "while listening to their song" +
  "\n" +
  "true love is in the words he writes" +
  "\n" +
  "he says it won’t be long.$" +
  "\n" +
  "‘til the gates are opened wide" +
  "\n" +
  "and he wants her to be there" +
  "\n" +
  "a smile upon her luscious lips" +
  "\n" +
  "and sunlight in her hair.$" +
  "\n" +
  "In all the grey and loneliness" +
  "\n" +
  "where every man’s alone" +
  "\n" +
  "he young man walks to writing class" +
  "\n" +
  "and writes his love a poem."

const PrisonArtsFoundation = () => {
  return (
    <Layout>
      <Helmet>
        <title>PAF - Pamela Mary Brown</title>
      </Helmet>
      <h2>Prison Arts Foundation</h2>

      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Text>
          ‘In my youth I regarded the Universe as an open book, printed in the
          language of physical equations, whereas now it appears to me as a text
          written in invisible ink, of which, in our rare moments of grace, we
          are able to decipher a small fragment.’
          <b> ― Arthur Koestler, The Invisible Writing </b>
        </Card.Text>
        <Card.Text>
          I began working in HMP Magilligan in 2011: delivering OCN
          Accreditations through the Artskills programme based at the Playhouse,
          Derry. In July 2015, I was offered the post of Writer-in-Residence by
          Prison Arts Foundation. At that time, I became Editor of Time In
          (Prison Magazine). The Koestler Arts Trust, Listowel Writers in
          Prison, The Reading Agency and Prison Reform Trust who grant Awards
          and Creative opportunities for prisoners is a constituent of my
          Writers Workshops and Education Programme in HMP Magilligan.
        </Card.Text>
        <PAImages />
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          Pamela Brown Prison Arts Foundation (PAF) Writer-in-Residence HMP
          Magilligan
        </Card.Title>
        <Card.Text>
          The inclusion of poems from the Creative Writing group based at HMP
          Magilligan during the John Hewitt Summer festival has many positives.
          Especially in a time when ‘lockdown’ makes every task much more
          challenging. Initially, there was the selection of the poems, deciding
          on readers, rehearsing the lines, and with help and support ensuring
          the recordings were achieved. All this is a team effort, and one which
          is unique and strongly reflects the support given to prison writers by
          Prison Arts Foundation and all the staff at Magilligan.
        </Card.Text>
        <Card.Text>
          The prison writers at Magilligan come from different backgrounds and
          life experiences. Abilities’ range from those who are extremely well
          read to those who can’t read and are self-taught. Nevertheless, all
          prisoners bring their stories and a willingness to trust the creative
          process, to self-reflect and ‘to give it a go’. Often the insights
          emerge through difficult issues such as addiction and this can be
          overwhelming. The group share their work on the landings and encourage
          new members. There is always a waiting list for the classes and a very
          high attendance. We write poems, flash fictions, short stories,
          memoirs, novels, plays, film scripts, and articles for Time In
          magazine, a PAF sponsored project.
        </Card.Text>
        <Card.Text>
          Creative writing provides a key to unlocking ideas, unlocking insular
          thinking, and unlocking potential. We share the work and peer critique
          each student while maintaining respectful spaces with their fellow
          writers. The arts have high significance in prison because there are
          possibilities of expression for the prisoner, for their version of
          literature, and definitively for rehabilitation because art creates a
          parallel world in which to view the actual world. Rather than the
          reductive concept of locking someone up, creativity unlocks interior
          aspects of personality.
        </Card.Text>
        <Card.Text>
          Supporting the arts in prison provides a vitally important role in the
          lives of prisoners: how they re-evaluate themselves; how they stay
          connected with their families, their wives, their mothers, their
          children; and with the survival of these relationships under duress.
          Overcoming extreme personal situations, resolving inner conflicts,
          ‘writing the self’ and bringing the self to inner harmony are among
          the mysteries of creative writing, music, and art.
        </Card.Text>
        <Card.Text>
          I would like to conclude by including a poem that demonstrates what I
          see every day during my work as Creative Writing Teacher in prison.
          The writing doesn’t have to be about something earth shattering, it
          doesn’t have to be about an over towering outcome but it is a way of
          getting though hour by hour, day by day, reaching out through a simple
          act of creativity, a few lines that connect one human being to
          another.
        </Card.Text>

        <Card.Title>Poem (GS)</Card.Title>

        {poem.split("$").map((paragraph, index) => {
          return <Paragraph paragraph={paragraph} key={index} />
        })}

        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          We hope you enjoy the recordings.
        </Card.Title>
        <div className="video-style">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/5FfaOEfTQSU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          BBC Radio 3 - Sunday Feature, Inside Stories
        </Card.Title>
        <Card.Text>
          Author Carlo Gebler has spent almost three decades working for Prison
          Arts Foundation as a teacher of creative writing. Based recently in
          Maghaberry he has worked in all the prisons in the North of Ireland
          including the Maze/Long Kesh H-Blocks. As part of this reflective
          radio documentary, Carlo also visits Pamela Brown and the creative
          writers’ group at HMP Magilligan. Producer: Conor Garrett for BBC
          Northern Ireland.
        </Card.Text>
        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={mp3}
              header="Inside Stories"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Title style={{ marginTop: "10px" }}>
          Interviews about my work with Prison Arts Foundation
        </Card.Title>
        <Card.Text>
          Creative Possibilities – Pamela Brown PAF Writer in Residence HMP
          Magilligan
          <br />
          <a
            href="http://www.prisonartsfoundation.com/creative-possibilities-pamela-brown-paf-writer-in-residence-hmp-magilligan/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Creative Possibilities – Pamela Brown
          </a>
        </Card.Text>
        <Card.Text>
          The Monthly interviews Pamela Brown, Writer in Residence, Prison Arts
          Foundation – Part 1
          <br />
          <a
            href="https://www.capartscentre.com/2020/11/the-monthly-interviews-pamela-brown-writer-in-residence-prison-arts-foundation-part-1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Monthly Interview - Part 1
          </a>
        </Card.Text>
        <Card.Text>
          The Monthly interviews Pamela Brown, Writer in Residence, Prison Arts
          Foundation – Part 2
          <br />
          <a
            href="https://www.capartscentre.com/2020/11/the-monthly-interviews-pamela-brown-writer-in-residence-prison-arts-foundation-part-2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Monthly Interview - Part 2
          </a>
        </Card.Text>
        <Card.Text>
          <a
            href="https://www.prisonartsfoundation.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            www.prisonartsfoundation.com
          </a>
        </Card.Text>
      </Card>
    </Layout>
  )
}

export default PrisonArtsFoundation
