import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { StaticImage } from "gatsby-plugin-image"

const Images = () => {
  return (
    <div className="carousal-container">
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showArrows
        showStatus={false}
        stopOnHover={false}
        className="container-C"
      >
        <div>
          <StaticImage src={"../images/TimeIn9.JPG"} alt={"Time in 9"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn10.JPG"} alt={"Time in 10"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn11.JPG"} alt={"Time in 11"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn12.JPG"} alt={"Time in 12"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn13.JPG"} alt={"Time in 13"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn14.JPG"} alt={"Time in 14"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn15Cover.JPG"} alt={"Time in 15"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn16.JPG"} alt={"Time in 16"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn17.JPG"} alt={"Time in 17"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn18.JPG"} alt={"Time in 18"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn19.JPG"} alt={"Time in 19"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn20.JPG"} alt={"Time in 20"} />
        </div>
        <div>
          <StaticImage src={"../images/TimeIn21.JPG"} alt={"Time in 21"} />
        </div>
        <div>
          <StaticImage src={"../images/Timein22.jpg"} alt={"Time in 22"} />
        </div>
      </Carousel>
    </div>
  )
}

export default Images
